.ant-tooltip {
  font-size: 16px;

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      &::before {
        background-color: var(--alto);
      }
    }

    .ant-tooltip-inner {
      background-color: var(--alto);
      color: var(--ebony);
      border-radius: 10px;
      p {
        color: var(--ebony);
      }
    }
  }

  &.tooltip-inicio {
    .ant-tooltip-content {
      margin-top: 30px;

      .ant-tooltip-arrow {
        margin-top: 15px;
      }
    }
  }
}
