.ant-steps {
  background: var(--naval);
  margin-bottom: 2rem;
  &.ant-steps-navigation {
    padding-top: 0;
    .ant-steps-item:hover {
      background: var(--degrade-step);
    }
    .ant-steps-item-container {
      padding: 1.2rem 0;
      display: block;
      transition: 0.5s;
      .ant-steps-item-content {
        display: -webkit-flex;
        -webkit-align-items: flex-start;
        display: flex;
        align-items: flex-start;
        margin: 0 auto;
        .ant-steps-item-title {
          -webkit-align-self: center;
          align-self: center;
        }
      }
    }
    .Empresa {
      .ant-steps-item-container {
        margin-left: -5px !important;
      }
    } 

  }

  @media (max-width: 767.98px) {
    background: transparent;
    margin-bottom: 0;
    .ant-steps-item-process .ant-steps-item-icon,
    .ant-steps-item-wait .ant-steps-item-icon,
    .ant-steps-item-finish .ant-steps-item-icon {
      background: var(--gigas);
      border-color: var(--gigas);
    }
    .ant-steps-item-tail::after {
      background: var(--gigas) !important;
      background-color: var(--gigas) !important;
    }
    .ant-steps-item-icon {
      font-size: 14px;
    }
  }

  &:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    display: block;
    margin: 0 0 4px 0;
  }

  &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 0;
    border-right: solid 2px var(--ebony);

    &::before {
      height: 1rem;
      bottom: -18px;
      width: 100%;
      left: 0;
    }

    &::after {
      border-top: 2px solid var(--ebony);
      border-right: 2px solid var(--ebony);
      top: initial;
      left: calc(100% - 2px);
      bottom: -16px;
      z-index: 1;
    }

    &.ant-steps-item-finish,
    &.ant-steps-item-active,
    &.ant-steps-item-wait {
      &::before {
        background-color: var(--comet);
      }
      &::after {
        background-color: var(--comet);
      }
    }

    &.completed {
      &::before {
        background-color: var(--marguerite);
      }
      &::after {
        background-color: var(--marguerite);
      }
    }

    &.ant-steps-item-active {
      background: var(--degrade-step);
      span i {
        color: css(--melrose);
      }
    }
  }

  .ant-steps-item-icon svg {
    fill: var(--alto);
  }

  .ant-steps-item-active {
    .ant-steps-item-icon svg {
      fill: var(--melroseSelected);
    }
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-description {
    color: var(--alto) !important;
    line-height: 1;
    white-space: normal;
    display: block;
    width: 100%;
    padding: 0;
    line-height: 20px;
    text-align: center;
  }

  .ant-steps-item-process .ant-steps-item-title {
    font-weight: 400;
  }

  .ant-steps-item-description {
    padding: 0 0 1rem 1rem;
  }

  .ant-steps-item-wait:hover
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-finish:hover
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps-item-process:hover
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: var(--melroseSelected) !important;
    line-height: 1;
    white-space: normal;
    text-align: center;
    display: block;
    width: 100%;
    line-height: 20px;
  }

  .ant-steps-item-wait:hover
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > span
    > i
    > svg,
  .ant-steps-item-finish:hover
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > span
    > i
    > svg,
  .ant-steps-item-process:hover
    > .ant-steps-item-container
    > .ant-steps-item-icon
    > span
    > i
    > svg {
    fill: var(--melroseSelected) !important;
  }

  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: var(--melroseSelected) !important;
  }

  .ant-steps-item-title,
  .ant-steps-item-description {
    font-size: 0.875rem;
  }

  // TABLET
  @include media-breakpoint-down(md) {
    width: auto;
    &.ant-steps-navigation {
      .ant-steps-item-container {
        width: 122px;
        .ant-steps-item-content {
          max-width: none;
        }
      }
      .ant-steps-item {
        overflow: hidden;
        text-align: center;
        &.ant-steps-item-active {
          overflow: visible;
        }
      }
    }
    &.ant-steps-horizontal:not(.ant-steps-label-vertical)
      .ant-steps-item::before {
      bottom: 0;
    }
    &.ant-steps-horizontal:not(.ant-steps-label-vertical)
      .ant-steps-item::before {
      height: 6px;
    }
    &.ant-steps-horizontal:not(.ant-steps-label-vertical)
      .ant-steps-item::after {
      display: none;
    }
  }
}
