thead.ant-table-thead {
  background: var(--martinique);
}

.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 12px;
}

.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 12px;
}

.ant-table-thead > tr > th {
  background: transparent;
  color: var(--alto);
  border: none;
}

.ant-table-tbody tr:nth-child(odd) {
  background-color: #686883;
}

.ant-table-tbody tr:nth-child(even) {
  background-color: var(--comet);
}

.ant-table-tbody > tr > td {
  border-bottom: none;
  color: var(--alto);
}

.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: transparent;
}

.ant-table-placeholder {
  background: transparent;
  border: none;
}
