@import "./_variables";

.file-wrapper a {
  padding: 0 16px 0 24px;
  height: 56px;
  display: flex;
  align-items: center;
  background: var(--martinique);
  border-radius: 12px;
  justify-content: space-between;
  .file-info {
    display: flex;
    align-items: center;
  }
  i {
    font-size: 2rem;
    margin-right: 14px;
    svg {
      fill: var(--alto);
    }
    &.download-icon {
      font-size: 1.5rem;
      justify-self: flex-end;
    }
  }
  h4 {
    margin: 0;
  }
}

.file-name {
  display: inline-block;
  max-width: calc(90%);
  overflow-wrap: anywhere;
}
