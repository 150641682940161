/* Mixin: Custom scrollbars for firefox */
// $color1: scrollbar color
// $color2: scrollbar background color

@mixin scrollbar-y ($color1, $color2) {
  padding-right: 0.75rem;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: $color1 $color2;
  scrollbar-width: auto;
  @media (max-width: 767.98px) {
    padding-right: 0;
  }
}

@mixin scrollbar-x ($color1, $color2) {
  padding-bottom: 0.75rem;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-color: $color1 $color2;
  scrollbar-width: auto;
}