html {
  font-size: 16px;
}

body {
  background-color: white;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.45;
  color: var(--alto);
}

p {
  margin-bottom: 1.25em;
  font-size: 0.875rem; // 14px
  color: var(--alto);
}

h1, h2 {
  line-height: 1.5;
}

h3, h4, h5 {
  line-height: 1.15; 
}

h1, h2, h3, h4, h5 {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  color: var(--alto);
}

h1, h3, h4 {
  font-weight: 400;
  color: var(--alto);
}

h2, h6 {
  font-weight: 300;
}

h1 {
  font-size: 1.625rem; // 26px
  color: var(--alto);
  @media (max-width: 767.98px)  {
    font-size: 1.3125rem; // 21px
  }
}

h2 {
  font-size: 1.375rem; // 22px
  color: var(--alto);
  @media (max-width: 767.98px)  {
    font-size: 1.0625rem; // 17px
  }
}

h3 {
  font-size: 1.125rem; // 18px
  color: var(--alto);
  font-weight: 400;
  @media (max-width: 767.98px)  {
    font-size: 0.875rem; // 14px
  }
}

h4 {
  font-size: 1rem; // 16px
  color: var(--alto);
  margin-bottom: 12px;
  font-weight: 400;
  @media (max-width: 767.98px)  {
    font-size: 0.875rem !important; // 12px
  }
}

h5 {
  font-size: 0.875rem; // 14px
  color: var(--alto);
  @media (max-width: 767.98px)  {
    font-size: 0.75rem; // 12px
  }
}

h6 {
  font-size: 0.75rem; // 12px
  color: var(--alto);
  margin: 0;
}

small,
.text_small {
  font-size: 0.6rem; // 9.6px
  color: var(--alto);
}

.icon-sesi {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
}
