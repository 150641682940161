@import "../style/mixins";

.ant-list {
  .item-list {
    padding: 8px 12px !important;
    border-radius: 4px !important;
    background-color: var(--ebony) !important;
    color: var(--alto);
    min-height: 40px;
    button {
      float: right;
    }
    &.cargos-list.input-disabled {
      background-color: var(--martinique) !important;
    }
  }
  .ant-list-item {
    border-bottom: none;
    background-color: var(--naval);
    margin-bottom: 8px;
    padding: 0.75rem 1rem;
    border-radius: 0.75rem;
    cursor: pointer;
    .ant-list-item-meta {
      .ant-list-item-meta-title {
        color: var(--alto);
        font-size: 1.0625rem;
        font-weight: 400;
        // text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      &.inativo {
        opacity: 0.4;
        .ant-list-item-meta-description {
          .tag-list {
            .ant-tag {
              border: 1px solid var(--alto);
              background: transparent;
            }
          }
        }
      }
    }
    .ant-list-item-meta-description {
      color: var(--alto);
    }
    .span-list span {
      margin-right: 0.5rem;
    }
  }
  .ant-list-footer {
    color: var(--melrose);
    p {
      margin: 0;
    }
  }
}

.ant-list-something-after-last-item
  .ant-spin-container
  > .ant-list-items
  > .ant-list-item:last-child {
  border-bottom: none;
}

.ant-list-empty-text {
  opacity: 0;
}

.ant-spin-container {
  min-height: calc(100vh - 385px);
  // max-height: calc(100vh - 485px);
  // @include scrollbar-y(var(--melrose), transparent);
}
