/* width */
/* https://css-tricks.com/custom-scrollbars-in-webkit/ */
::-webkit-scrollbar {
  width: 8px;

  &:horizontal {
    height: 8px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--melrose);
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--melrose);
  opacity: 0.8;
}

::selection {
  color: #fff;
  background: green;
}

a {
  color: var(--alto);
}

hr {
  margin: 1rem auto;
  border-color: var(--comet);
}

.spin {
  position: absolute;
  top: calc(50vh - 27px);
  left: calc(50vw - 42px);
  .ant-spin-dot-item {
    background-color: var(--marguerite);
  }
  .ant-spin-text {
    color: var(--marguerite);
    margin-top: 0.5rem;
  }
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover,
.ant-select-selection--multiple .ant-select-selection__choice {
  color: var(--alto);
  background: var(--comet);
  border: none;
  border-radius: 2rem;
  font-weight: 400;
  text-transform: lowercase;
  &::first-letter,
  .ant-select-selection__choice__content::first-letter {
    text-transform: uppercase;
  }
  &.recusada {
    border: solid 2px var(--red);
    background: transparent;
  }
  &.aceita {
    border: solid 2px var(--green);
    background: transparent;
  }
  &.pendente {
    border: dashed 2px var(--comet);
    background: transparent;
    text-transform: none;
    .icon-sesi {
      font-size: 1rem;
    }
  }
}

.status-tag.ant-tag {
  text-transform: none;
  width: auto !important;
}