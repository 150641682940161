.ant-collapse {
  font-size: 1rem;
  &.ant-collapse-borderless {
    background-color: transparent;
    & > .ant-collapse-item {
      background-color: var(--martinique);
      border-bottom: none;
      margin-bottom: 1rem;
      border-radius: 0.75rem;
    }
  }
  & > .ant-collapse-item > .ant-collapse-header {
    color: var(--alto);
    padding: 1rem 1.5rem;
  }
  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 1.5rem;
    left: auto;
    font-size: 1.75rem;
  }
  .form-section {
    border-top: solid 1px var(--melrose);
    padding-top: 0.5rem;
  }
  & > .ant-collapse-item > .ant-collapse-header > .ant-collapse-extra > .ant-badge-not-a-wrapper > .ant-badge-count {
    margin-right: 2.5rem;
    margin-top: -0.25rem;
    background: var(--gigas);
    line-height: 30px;
    height: 30px;
    width: 30px;
    border-radius: 20px;
    box-shadow: 0 0 0 1px var(--gigas) inset;
  }
}

.dados-da-empresa {
  .ant-collapse.ant-collapse-borderless > .ant-collapse-item {
    margin-bottom: 0.5rem;
  }
}
