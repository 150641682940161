.error-page {
	margin: 8% 0 0 0;
}

.ant-layout .ant-layout-content {
	background-position: bottom right;
}

.error-title {
	font-size: 7.5rem; // 120px
}

.back-btn {
	//width: 25% !important;
	margin-top: 2.75rem;
}

.error-page::before {
	content: "";
	margin: 1rem 0 0 15rem;
	width: 196px;
	height: 344px;
	position: absolute;
	background-image: url(./images/aroldo-pagina-de-erro.svg);
	background-size: cover;
	background-repeat: no-repeat;
	@media (max-width: 767.98px) {
		width: 82px;
		height: 144px;
		margin: 1rem 0 0 17rem;
	}
}