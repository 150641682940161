.ant-tabs {
  .ant-tabs-ink-bar {
    width: 100% !important;
    transform: translate3d(0px, 0px, 0px) !important;
    height: 20px;
    border-radius: 20px 0 0 0;
    background-color: #282944;
  }

  .ant-tabs-tabpane {
    &.confg {
      padding: 0 0 0 0;
    }
  }

  .ant-tabs-bar {
    border-bottom: none;
    margin: 0 0 2px 0;
    &:not(.ant-tabs-card-bar) {
      display: none;
    }
  }

  // TAB TIPO CARD
  &.ant-tabs-card {
    .ant-tabs-nav {
      width: 100%;
    }
    .ant-tabs-card-bar {
      margin: 0;
      .ant-tabs-tab {
        font-weight: 400;
        width: 50%;
        margin-right: 0;
        border: none;
        text-align: center;
        background: var(--comet);
        border-radius: 10px 10px 0 0;
        color: var(--alto);
        font-size: 14px;
      }
      .ant-tabs-tab-active {
        background: var(--martinique);
      }
    }
    .comet-bg {
      background-color: var(--martinique);
      border-radius: 0 0 0.75rem 0.75rem;
    }
  }
}
