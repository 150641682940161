@import "../../shared/LayoutApp/_/style/_variables.css";
@import "../../shared/LayoutApp/_/style/mixins";

.company-cards {
  margin: 1rem 0 0;
  overflow-y: scroll;
  max-height: calc(100vh - 281px);
  .cards {
    @include scrollbar-y(var(--melrose), transparent);
    @media (max-width: 767.98px) {
      padding-right: 0 !important;
    }
    
  }
  .ant-card {
    min-height: 10rem; // 160px para tratar casos de empresas que possuem nome em 2 linhas
    margin: 0 0.75rem 1.5rem 0.75rem;
  }

  @media (max-width: 767.98px) {
    .ant-card {
      min-height: 8.125rem; //130px
    }
    h4 {
      font-size: 14px;
      font-weight: 400;
    }
    h5 {
      font-size: 12px;
      font-weight: 300;
    }
  }
}

.align-self-center {
  Button {
    margin-top: 16px;
  }
}