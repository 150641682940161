@import "./_variables";

.button-list {
  margin-bottom: 0.75rem; //12px
  display: flex;
  .ant-btn:not(:last-child) {
    margin-right: 1.5rem; //20px
  }
  .ant-btn-background-ghost.ant-btn-primary:hover,
  .ant-btn-background-ghost.ant-btn-primary:focus,
  .ant-btn-background-ghost.ant-btn-primary {
    color: var(--alto) !important;
    border-color: var(--gigas);
  }
}

a.ant-btn {
  padding-top: 12px !important;
  line-height: normal;
}

.ant-upload-disabled .ant-btn {
  cursor: not-allowed;
  border: dashed 1px var(--disabled-color);
  background-color: var(--martinique);
  color: var(--disabled-color) !important;
  &:hover,
  &:focus,
  &:active,
  &.active {
    text-decoration: none;
    background: var(--martinique);
    border-color: var(--disabled-color);
    text-shadow: none;
    box-shadow: none;
  }
}
.ant-btn {
  border-radius: 4px;
  height: auto;
  padding: 8px 16px;
  background-color: var(--marguerite);
  color: var(--melrose);
  border-color: var(--marguerite);
  border-width: 2px;
  &.ant-btn-background-ghost {
    display: flex;
    align-items: center;
    color: var(--alto);
    .icon-sesi {
      font-size: 18px;
      vertical-align: -5px;
    }
  }
  &:not(.ant-btn-block) {
    width: auto;
  }
  &.ant-btn-round {
    border-radius: 32px;
  }
  &:hover,
  &:focus,
  &:active,
  &.active {
    text-decoration: none;
    background: var(--marguerite);
    color: var(--melroseSelected);
    border-color: var(--marguerite);
    text-shadow: 0px 0px 7px var(--alto);
    box-shadow: 0px 0px 7px var(--marguerite);
  }
  &.ant-btn-circle {
    border-radius: 50%;
  }
}

.ant-btn-primary {
  background-color: var(--comet);
  border-color: var(--comet);
  text-transform: uppercase;
  &:hover,
  &:focus {
    background-color: var(--marguerite);
    border-color: var(--marguerite);
    text-shadow: none;
    box-shadow: none;
  }
}

.ant-btn-link {
  color: var(--alto);
  padding: 0;
  font-weight: 700;
  &:hover,
  &:focus {
    color: var(--melrose);
  }
  &.ant-btn-background-ghost:hover,
  &.ant-btn-background-ghost:focus {
    color: var(--melrose);
    text-shadow: none;
    box-shadow: none;
  }
}

.ant-btn-danger,
.ant-btn-danger:hover {
  background-color: var(--red);
  border-color: var(--red);
  color: var(--alto);
  text-transform: uppercase;
  &.ant-btn-round {
    text-transform: inherit;
  }
  &:hover {
    opacity: 0.8;
    box-shadow: none;
    text-shadow: none;
  }
}

.ant-btn-background-ghost.ant-btn-danger:hover {
  color: var(--alto);
}

.ant-btn-accept,
.ant-btn-accept:hover {
  background-color: var(--green);
  border-color: var(--green);
  color: var(--alto);
  text-transform: uppercase;
  &.ant-btn-round {
    text-transform: inherit;
  }
  &:hover {
    opacity: 0.8;
    box-shadow: none;
    text-shadow: none;
  }
}

.add.ant-btn-link {
  font-size: 1rem;
  display: -webkit-flex; /* Safari */
  -webkit-align-items: flex-start; /* Safari 7.0+ */
  display: flex;
  align-items: flex-start;
  color: var(--alto);
  padding: 0.5rem 0;
  height: auto;
  font-weight: 400;
  & > .anticon + span,
  & > span + .anticon {
    margin-left: 12px;
  }
  .anticon,
  span {
    -webkit-align-self: center; /* Safari 7.0+ */
    align-self: center;
    svg {
      fill: var(--gigas);
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  .anticon {
    font-size: 28px;
  }
  &.ant-btn-background-ghost.ant-btn-link:hover,
  &.ant-btn-background-ghost.ant-btn-link:focus {
    color: var(--alto);
    text-shadow: 0px 0px 1rem var(--melrose);
    .anticon svg {
      box-shadow: 0px 0px 1rem var(--gigas);
      border-radius: 100%;
    }
  }
}

.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  background: var(--martinique);
  color: var(--alto);
  border: none;
  opacity: 0.25;
}
.action-button {
  font-size: 1.5rem;
  padding: 0;
  &.ant-btn-background-ghost.ant-btn-link:hover,
  &.ant-btn-background-ghost.ant-btn-link:focus {
    color: var(--alto);
  }
}

.ant-dropdown {
  .ant-dropdown-menu-item {
    .icon-sesi {
      font-size: 18px;
      svg {
        fill: var(--ebony);
      }
    }
    .ant-dropdown-menu-item > a,
    .ant-dropdown-menu-submenu-title > a {
      color: var(--ebony);
    }
  }
}

.ant-btn {
  &.add {
    margin-bottom: 16px;
    padding: 0 0 0 0;
    border: none;
    .add-circle {
      font-size: 2rem;
      margin-right: 12px;
      margin-left: -2px;
    }
    .trash {
      font-size: 1.5rem;
      margin-right: 12px;
      margin-left: -2px;
      background-color: var(--marguerite);
      border-radius: 50%;
      padding: 4px;
    }

  }

}

.ant-col .ant-form-item-label {
  padding-right: 24px;
}

.ant-radio-inner::after,
.ant-radio-inner:focus {
  background-color: var(--gigas);
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--gigas);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--gigas);
}

span.ant-radio + * {
  color: var(--alto);
  vertical-align: middle;
}

.ant-radio-group.ant-radio-group-outline {
  width: 100%;
}

.ant-radio-button-wrapper-checked {
  background-color: var(--alto);
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: var(--martinique);
}

.ant-radio-button-wrapper:first-child {
  border-left: transparent;
}

.ant-select-selection {
  border-color: var(--martinique) !important;
}

.ant-radio-button-wrapper {
  background: var(--comet);
  border-color: var(--comet);
  text-align: center;
  &.ant-radio-button-wrapper-checked {
    border: 1px solid var(--gigas);
  }
}

.ant-radio-button-checked ~ label {
  color: var(--gigas);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--martinique) !important;
  color: var(--alto);
  &:hover {
    color: var(--alto);
  }
}

.ant-radio-button-wrapper:hover {
  color: var(--alto);
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree-checkbox-inner,
.ant-tree-checkbox-inner:hover,
.ant-form .ant-checkbox .ant-checkbox-inner {
  background-color: var(--marguerite);
  border: 1px solid var(--alto);
}

.ant-tree li .ant-tree-node-content-wrapper,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
}

.ant-tree li,
.ant-tree-checkbox {
  padding: 0;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: var(--gigas);
}

.ant-tree-checkbox-disabled.ant-tree-checkbox-checked
  .ant-tree-checkbox-inner::after {
  border-color: var(--comet);
}

.ant-tree-treenode-switcher-open li {
  display: flex;
}

.ant-tree-checkbox-checked::after {
  border-color: var(--comet);
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: var(--gigas);
}

.ant-tree > li:first-child {
  padding-top: 0;
}

.ant-btn-background-ghost:hover {
  box-shadow: none;
}

.ant-btn-background-ghost[disabled] {
  color: var(--alto) !important;
}
