.ant-modal {
  top: 40%;

  .ant-modal-close {
    color: var(--alto);
  }

  .ant-modal-content {
    background-color: var(--martinique);
    border-radius: 0.75rem;
  }

  .ant-modal-close-x .anticon {
    color: var(--alto);
  }

  .ant-modal-body {
    margin-top: 6rem;
    max-height: calc(100vh - 400px);
    @include scrollbar-y(var(--melrose), transparent);
    padding-bottom: 0;

    &::before {
      content: url("./../../../../constants/images/aroldo-explicando-esquerda.svg");
      position: absolute;
      width: 175px;
      top: -157px;
      left: 22px;
    }

    h5 {
      padding-top: 12px;
    }
  }

  .ant-modal-footer,
  .ant-modal-confirm-btns {
    border: none;
    padding: 20px;
    text-align: center;

    .ant-btn {
      width: calc(50% - 8px);
      span {
        color: var(--melroseSelected) !important;
      }
    }

    :not(.ant-btn-primary) {
      //background-color: transparent;
      span {
        color: var(--alto) !important;
      }

      &:hover {
        text-shadow: none;
      }
    }

    // .ant-btn-primary:hover,
    // .ant-btn-primary:focus {
    //   background-color: var(--marguerite);
    //   border-color: var(--marguerite);
    // }
  }

  &.ant-modal-confirm {
    .ant-modal-body {
      padding: 24px;

      .anticon {
        display: none;
      }

      & > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
        margin: 0;
      }
    }

    .ant-modal-confirm-body {
      .ant-modal-confirm-title {
        color: var(--alto);
        font-size: 20px;
        text-align: center;
        margin: 0;
      }

      .ant-modal-confirm-content {
        color: var(--alto);
        font-size: 1rem;
        text-align: center;
        margin: 0;
      }
    }

    .ant-modal-confirm-btns {
      margin-top: 48px;
      padding: 0;
      width: 100%;

      // .ant-btn {
      //   display: none;
      // }

      .ant-btn-primary {
        display: block;
        float: right;
      }
    }
  }
}

.ant-modal.medico {
  .ant-modal-body {
    &:after {
      content: none;
    }
  }
}
