@import "./_variables";

.espaco-fisico {
  .setores-list {
    .ant-list-item {
      cursor: default;
      .ant-card {
        .plan-floor-type-item {
          flex-wrap: wrap;
          justify-content: center;
          width: 100px;
          height: 100px;
          margin: 0 auto;
          display: -webkit-flex; /* Safari */
          -webkit-align-items: flex-start; /* Safari 7.0+ */
          display: flex;
          align-items: flex-start;
          color: var(--alto);
          text-align: center;
          .plan-floor-type {
            -webkit-align-self: center; /* Safari 7.0+ */
            align-self: center;
          }
        }
        .fa-setor {
          font-size: 1.5rem;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
  .espaco-fisico-card {
    // margin-top: 1rem;
    .ant-card-head {
      padding: 0 0 0 24px;
      border-bottom: none;
    }
    .ant-card-head-title {
      color: var(--alto);
      text-align: center;
    }
    .icon-sesi.edit-comment {
      font-size: 1.1rem;
      cursor: pointer;
    }
  }
  .admin-modelos-de-planta {
    .ant-card {
      .ant-card-extra {
        padding: 0;
      }
      .ant-card-body {
        .plan-floor-type-item {
          width: 75px;
          height: 75px;
        }
      }
    }
  }
  // .ant-spin-container {
  //   min-height: calc(100vh - 330px);
  // }
}