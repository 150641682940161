.ant-card {
  background: var(--martinique);
  border-radius: 4px;
  h5,
  h4 {
    color: var(--alto);
    font-weight: 400;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &.resumo {
    background: transparent;
    color: var(--alto);
    margin-bottom: 1.5rem;
    .ant-card-head {
      background: var(--martinique);
      color: var(--alto);
      width: 80%;
      border-radius: 1.25rem 1.25rem 0 0;
      border: none;
      margin-bottom: -8px;
      .ant-card-head-title {
        font-size: 0.875rem;
        text-transform: uppercase;
      }
    }
    .ant-card-body {
      background: var(--comet);
      border-radius: 0.75rem;
      height: 285px;
      padding: 16px 8px 20px 20px;
    }
    .ant-list {
      height: 100%;
      //overflow: hidden auto;
      &.ant-list-split .ant-list-header {
        border-bottom: none;
      }
      .ant-list-header {
        padding-top: 0;
        padding-bottom: 1rem;
      }
      .ant-list-items {
        min-height: 185px;
        @include scrollbar-y(var(--melrose), transparent);
        &::-webkit-scrollbar-thumb {
          background: var(--martinique);
        }
      }
      .ant-list-item {
        color: var(--alto);
        padding: 6px 0;
        background-color: transparent;
        margin-bottom: 0;
      }
    }
  }
  .veja-mais {
    color: var(--melrose);
  }
}
