:root {
  --ebony: #192136;
  --melrose: #d1deff;
  --martinique: #154071;
  --alto: #eeeeee;
  --comet: #475f92;
  --gigas: #114680;
  --marguerite: #17508f;
  --degrade-menu: #114680;
  --melroseSelected: #eeeeee;
  --naval: #122858;
  --esc: #1a3570;
  --app-gray: #e9e9e9;
  --dark: #182037;

  --pendency: #f2c94c;
  --green: #00738c;
  --green2: #27AE60;
  --red: #a34949;
  --yellow: #f2c94c;
  --disabled-color: #8d8ab4;
  --degrade-fundo: linear-gradient(180deg, #192136, #192136);
  --degrade-step: linear-gradient(180deg, #17508f, #17508f), #17508f;
}
